


















































































































































































































































































































.container {
	background-color: #f8f8f8;
	height: 100%;
	font-family: PingFangSC-Regular;
	// height: calc(100vh + 110px);
	.content {
		background-color: #f8f8f8;
		padding-bottom: 1rem;
	}
}
.user_msg {
	margin-top: 0.2rem;
	border-top-left-radius: 0.32rem;
	border-top-right-radius: 0.32rem;
	background-color: #ffffff;
	font-family: PingFang SC;
	padding: 0.15rem 0;
	padding-bottom: 0;
	.msg {
		padding: 0 0.2rem 0 0.2rem;
		display: flex;
		justify-content: center;
		.msg-top {
			display: flex;
			align-items: center;
			padding-top: 0.32rem;
			.msg_left {
				display: flex;
				flex-direction: column;
				align-items: center;
				.img {
					width: 1.2rem;
					height: 1.2rem;
					img {
						border-radius: 0.5rem;
						width: 100%;
						height: 100%;
					}
				}
				.name {
					margin-top: 0.08rem;
					text-align: center;
					color: #333333;
					font-size: 0.28rem;
					font-weight: 500;
				}
			}
			.msg_right {
				margin-left: 0.76rem;
				.time {
					color: #333333;
					font-size: 0.24rem;
					font-weight: 500;
				}
				.VIP {
					margin-top: 0.1rem;
					display: flex;
					align-items: center;
					.img_vip {
						// width: 0.24rem;
						// height: 0.24rem;
						img {
							width: 0.252399rem;
							height: 0.2218rem;
						}
					}
					.vip_number {
						margin-left: 0.1rem;
						font-size: 0.28rem;
						color: #efba0d;
					}
				}
				.type {
					margin-top: 0.12rem;
					color: #efba0d;
					background-color: #fffae8;
					padding: 0.06rem 0 0.04rem 0.2rem;
					font-size: 0.24rem;
					font-weight: 500;
					border-radius: 0.04rem;
				}
			}
		}
	}
	.shuju {
		border-top: 0.01rem solid rgba(211, 217, 222, 0.49);
		padding: 0.3rem 0;
		margin: 0.2rem;
		display: flex;
		align-items: center;
		justify-content: space-around;
		.list1 {
			padding-right: 0.2rem;
			padding-left: 0.2rem;
			display: flex;
			align-items: center;
			border-right: 0.02rem solid rgba(211, 217, 222, 0.5);
			.top_left {
				text-align: center;
			}
			.txt {
				color: #6f7a8b;
				font-size: 0.24rem;
			}
			.txt1 {
				color: #f5465d;
				font-size: 0.24rem;
			}
			.left_num {
				color: #333333;
				font-size: 0.32rem;
				font-weight: bold;
			}
		}
		.list2 {
			display: flex;
			align-items: center;
			// padding: 0 0.2rem;
			.top_left {
				text-align: center;
			}
			.txt {
				color: #6f7a8b;
				font-size: 0.24rem;
			}
			.txt1 {
				color: #f5465d;
				font-size: 0.24rem;
			}
			.left_num {
				color: #333333;
				font-size: 0.32rem;
				font-weight: bold;
			}
		}
		.list3 {
			padding-right: 0.2rem;
			padding-left: 0.3rem;
			display: flex;
			align-items: center;
			border-right: 0.02rem solid rgba(211, 217, 222, 0.5);
			.top_left {
				text-align: center;
			}
			.txt {
				color: #6f7a8b;
				font-size: 0.24rem;
			}
			.txt1 {
				color: #f5465d;
				font-size: 0.24rem;
			}
			.left_num {
				color: #333333;
				font-size: 0.32rem;
				font-weight: bold;
			}
		}
	}
	.list {
		margin: 0 0.68rem;
		padding-bottom: 0.5rem;
		.list_item {
			padding: 0.18rem 0;
			border-bottom: 1px solid #e9e9e9;
			display: flex;
			justify-content: space-between;
			align-items: center;
			font-size: 0.28rem;
			color: #333333;
			font-weight: 600;
		}
		.list-right {
			text-align: right;
		}
		.list_item:last-child {
			padding-bottom: 0.5rem;
		}
	}
}
.bottom {
	position: fixed;
	left: 0;
	bottom: 0;
	background-color: #ffffff;
	padding: 0;
	.button {
		margin: 0.14rem 0.3rem 0.14rem 0.28rem;
		width: 6.9rem;
		height: 0.88rem;
		line-height: 0.88rem;
		background-color: #fad538;
		color: #333333;
		font-size: 0.32rem;
		text-align: center;
		border-radius: 0.12rem;
		font-weight: 500;
	}
}
.tan {
	width: 5.6rem;
	height: 3.4rem;
	// background-image: url(../../assets/imgs/tan_bg.png);
	// background-repeat: no-repeat;
	// background-size: 100% 3.6rem;
	.tan_no_txt {
		padding: 0.69rem 0.82rem 0 0.6rem;
		color: #333333;
		font-size: 0.32rem;
		text-align: center;
		font-weight: 500;
	}
	.tan_no_txt1 {
		padding: 0 1.2rem;
		padding-top: 0.63rem;
		color: #333333;
		text-align: left;
		font-weight: 500;
		color: #333333;
		display: flex;
		align-items: center;
		.tan_no_txt1-left {
			color: #333333;
			font-size: 0.32rem;
		}
		.tan_no_txt1-right {
			color: #333333;
			font-size: 0.32rem;
			margin-left: 0.3rem;
		}
	}
	.tan_no_txt2 {
		margin-top: 0.05rem;
		padding: 0 1.2rem;
		color: #333333;
		font-weight: 500;
		text-align: left;
		display: flex;
		align-items: center;
		.tan_no_txt1-left {
			color: #333333;
			font-size: 0.32rem;
		}
		.tan_no_txt1-right {
			color: #333333;
			font-size: 0.32rem;
			margin-left: 0.3rem;
		}
	}
	.input {
		margin-top: 0.08rem;
		padding: 0 0.7rem 0 0.72rem;
		color: #333333;
		font-weight: bold;
		input {
			width: 100%;
			border: none;
			border-bottom: 1px solid #d3d9de;
			padding: 0.04rem 0;
			font-size: 0.32rem;
		}
	}
	.tan_no_button {
		width: 2.62rem;
		height: 0.66rem;
		line-height: 0.66rem;
		text-align: center;
		// margin: 0.47rem 1.2rem 0 1.32rem;
		font-size: 0.28rem;
		color: #ffffff;
		background-color: #fad538;
		border-radius: 0.08rem;
		margin: 0 auto;
		margin-top: 0.45rem;
	}
	.tishi {
		text-align: center;
		color: #f5465d;
		font-size: 0.24rem;
		transform: scale(0.666);
	}
}
.tan1 {
	width: 5.6rem;
	height: 3.4rem;
	// background-image: url(../../assets/imgs/tan_bg.png);
	// background-repeat: no-repeat;
	// background-size: 100% 3.6rem;
	.tan_no_txt {
		padding: 0.69rem 0.82rem 0 0.6rem;
		color: #333333;
		font-size: 0.32rem;
		text-align: center;
		font-weight: 500;
	}
	.tan_no_txt1 {
		padding: 0.69rem 0.82rem 0 0.72rem;
		color: #333333;
		font-size: 0.24rem;
		text-align: left;
		font-weight: 500;
		color: #333333;
	}
	.tan_no_txt2 {
		margin-top: 0.05rem;
		color: #333333;
		font-size: 0.16rem;
		transform: scale(0.75);
		display: flex;
		justify-content: space-between;
		font-weight: 500;
		text-align: left;
		.right {
			color: #f5465d;
		}
	}
	.input {
		margin-top: 0.08rem;
		padding: 0 0.7rem 0 0.72rem;
		color: #333333;
		font-weight: bold;
		input {
			width: 100%;
			border: none;
			border-bottom: 1px solid #d3d9de;
			padding: 0.04rem 0;
			font-size: 0.32rem;
		}
	}
	.tan_no_button {
		margin: 0.47rem 1.2rem 0 1.32rem;
		padding: 0.1rem 0.36rem 0.08rem 0.36rem;
		font-size: 0.28rem;
		color: #ffffff;
		background-color: #fad538;
		text-align: center;
		border-radius: 0.08rem;
	}
}

@font-size-sm: 14px;@font-size-md: 16px;@font-size-lg: 18px;@goods-action-button-danger-color: #7232dd;@goods-action-button-warning-color: #3eaf7c;